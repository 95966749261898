import React from "react"
import { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Box, CircularProgress,
  Grid, Typography, IconButton,
  Dialog, DialogContent, TextField,
  MenuItem
} from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 15px'
    }
  },
  item: {
    height: '400px',
    border: '1PX solid black',
    borderRadius: 5,
    backgroundColor: '#ddd',
    padding: '20px 0px'
  },
  test: {
    width: '10px',
    backgroundColor: '#88C'
  },
  test2: {
    width: '10px',
    backgroundColor: '#113399'
  },
  statBlock: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    margin: '10px 0px'
  },
  overall: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  floatingButton: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: "lightblue",
    '&:hover': {
      backgroundColor: 'lightblue',
      color: 'green',
    }
  },
  filterGrid: {
    margin: "10px 0px"
  },
  validateButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }
}));

function Stats(props) {
  const classes = useStyles()
  const theme = useTheme()

  const days = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"]

  const [applications, setApplications] = useState(null)
  const [stats, setStats] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dialog, setDialog] = useState(false)
  const [doOnce, setDoOnce] = useState(true)

  const [currentApplication, setCurrentApplication] = useState({ name: "None", methods: [], id: "0" })
  const [currentMethod, setCurrentMethod] = useState("None")
  const [currentType, setCurrentType] = useState("All")
  const [currentTimeType, setCurrentTimeType] = useState("Period")
  const [currentPeriod, setCurrentPeriod] = useState("1 week")
  const [currentStartDate, setCurrentStartDate] = useState("")
  const [currentEndDate, setCurrentEndDate] = useState("")
  const [currentDate, setCurrentDate] = useState("")

  function getAPIParameters() {
    return (currentPeriod === "None" ? "%20" : currentPeriod) + "/" +
      (currentDate === "" ? "%20" : currentDate) + "/" +
      (currentStartDate === "" ? "%20" : currentStartDate) + "/" +
      (currentEndDate === "" ? "%20" : currentEndDate) + "/" +
      (currentType === "All" ? "%20" : currentType) + "/" +
      (currentApplication.name === "None" ? "%20" : currentApplication.name) + "/" +
      (currentMethod === "None" ? "%20" : currentMethod)
  }

  useEffect(() => {
    if (doOnce) {
      setDoOnce(false)
      props.httpcall("get", "getcontext", null, callbackContext)
      props.httpcall("get", "stats/" + getAPIParameters(), null, callbackLogs)
    }
  })

  function callbackContext(data) {
    setApplications(data)
    setLoading(false)
  }

  function callbackLogs(data) {
    setStats(data)
    setLoading(false)
    setDialog(false)
    props.statusUpdate("info", data.totalLogs + " logs loaded")
  }

  const handleApplication = (event) => {
    if (event.target.value === "None") {
      setCurrentApplication({ name: "None", methods: [], id: "0" })
    } else {
      for (let i in applications) {
        if (applications[i].name === event.target.value) {
          setCurrentApplication(applications[i])
        }
      }
    }
  }

  const handleMethod = (event) => {
    if (event.target.value === "None") {
      setCurrentMethod("")
    } else {
      setCurrentMethod(event.target.value)
    }
  }

  const handleTimeType = (event) => {
    setCurrentTimeType(event.target.value)
    setCurrentPeriod("None")
    setCurrentStartDate("")
    setCurrentEndDate("")
    setCurrentDate("")
  }

  return (
    <div className={classes.content}>
      {stats ?
        <Grid container spacing={0} justifyContent="center">
          <Grid item xs={12} md={6} className={classes.item}>
            <Grid container spacing={0} justifyContent="space-around">
              {days.map((day, index) => (
                <Grid item key={day}>
                  <Grid container spacing={0} direction="column">
                    <div className={classes.statBlock}>
                      <div className={classes.test} style={{ height: 300 - (stats.logsByDays[index] / stats.totalLogs * 300) + "px" }} />
                      <div className={classes.test2} style={{ height: (stats.logsByDays[index] / stats.totalLogs * 300) + "px" }} />
                    </div>
                    <Typography align='center'>
                      {day}
                    </Typography>
                    <Typography align='center'>
                      {stats.logsByDays[index]}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item md={1} xs={0} />
          <Grid item xs={12} md={4} className={classes.item}>
            <div className={classes.overall}>
              <Typography variant="h6" style={{ fontWeight: "bold", fontFamily: "Calibri" }}>
                Application : {currentApplication.name === "Toutes" ? "" : currentApplication.name}
              </Typography>
              <Typography variant="h6" style={{ fontWeight: "bold", fontFamily: "Calibri" }}>
                Methode : {currentMethod === "Toutes" ? "" : currentMethod}
              </Typography>
              <Typography variant="h4" style={{ fontWeight: "bold", fontFamily: "Calibri", color: "#113399" }}>
                {stats.totalLogs} Logs
              </Typography>
            </div>
          </Grid>
        </Grid>
        : <></>}
      <IconButton className={classes.floatingButton} onClick={() => setDialog(true)}>
        <SortIcon fontSize="large" />
      </IconButton >
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent style={{ minWidth: "300px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h5">
              Filter
            </Typography>
            <Grid item className={classes.filterGrid}>
              <TextField variant="outlined" select label="Application" value={currentApplication.name === "None" ? "" : currentApplication.name} fullWidth onChange={handleApplication}>
                <MenuItem value="None">
                  None
                </MenuItem>
                {applications !== null ?
                  applications.map((application) => {
                    return (
                      <MenuItem key={application.name} value={application.name}>
                        {application.name}
                      </MenuItem>
                    )
                  })
                  : <MenuItem value={""}>
                    None
                  </MenuItem>}
              </TextField>
            </Grid>
            {currentApplication.name !== "None" ?
              <Grid item className={classes.filterGrid}>
                <TextField select label="Method" value={currentMethod === "None" ? "" : currentMethod} variant="outlined" fullWidth onChange={handleMethod}>
                  <MenuItem value="None">
                    None
                  </MenuItem>
                  {currentApplication.methods.map((method) => {
                    return (
                      <MenuItem key={method} value={method}>
                        {method}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              : <></>}
            <Grid item className={classes.filterGrid}>
              <TextField select label="Log type" value={currentType} variant="outlined" fullWidth onChange={(event) => setCurrentType(event.target.value)}>
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Info">Info</MenuItem>
                <MenuItem value="Error">Error</MenuItem>
              </TextField>
            </Grid>
            <Grid item className={classes.filterGrid}>
              <TextField select label="Time mode" value={currentTimeType === "None" ? "" : currentTimeType} variant="outlined" fullWidth onChange={handleTimeType}>
                <MenuItem value="None">None</MenuItem>
                <MenuItem value="Period">Period</MenuItem>
                <MenuItem value="Start-End">Start-End</MenuItem>
                <MenuItem value="Specific Date">Specific Date</MenuItem>
              </TextField>
            </Grid>
            {currentTimeType === "Period" ?
              <Grid item className={classes.filterGrid}>
                <TextField select label="Period" value={currentPeriod} variant="outlined" fullWidth onChange={(event) => setCurrentPeriod(event.target.value)}>
                  <MenuItem value="today">
                    today
                  </MenuItem>
                  <MenuItem value="1 week">
                    1 week
                  </MenuItem>
                  <MenuItem value="1 month">
                    1 month
                  </MenuItem>
                  <MenuItem value="3 month">
                    3 month
                  </MenuItem>
                  <MenuItem value="6 month">
                    6 month
                  </MenuItem>
                  <MenuItem value="all">
                    all
                  </MenuItem>
                </TextField>
              </Grid>
              : <></>}
            {currentTimeType === "Start-End" ?
              <Grid item>
                <div className={classes.filterGrid}>
                  <TextField label="Start Date (dd-mm-yyyy)" value={currentStartDate} variant="outlined" fullWidth onChange={(event) => setCurrentStartDate(event.target.value)} />
                </div>
                <div item className={classes.filterGrid}>
                  <TextField label="End Date (dd-mm-yyyy)" value={currentEndDate} variant="outlined" fullWidth onChange={(event) => setCurrentEndDate(event.target.value)} />
                </div>
              </Grid>
              : <></>}
            {currentTimeType === "Specific Date" ?
              <Grid item className={classes.filterGrid}>
                <TextField label="Date (dd-mm-yyyy)" value={currentDate} variant="outlined" fullWidth onChange={(event) => setCurrentDate(event.target.value)} />
              </Grid>
              : <></>}
            <Grid item className={classes.validateButton}>
              {loading ?
                <Box display="flex" alignItems="center" justifyContent="center" margin="10px">
                  <CircularProgress />
                </Box>
                : <IconButton style={{ color: "green" }} onClick={() => { setLoading(true); props.httpcall("get", "stats/" + getAPIParameters(), null, callbackLogs) }}>
                  <CheckCircleIcon fontSize="large" />
                </IconButton>}
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Stats;
