import { React, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Home from "./pages/Home"
import Stats from "./pages/Stats"
import {
  BrowserRouter,
  Route,
  Routes,
  useParams,
  Link,
  useNavigate
} from "react-router-dom";
import {
  Button,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SortIcon from '@material-ui/icons/Sort';

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: 'flex',
    height: '50px',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#228822'
  },
  link: {
    textTransform: 'none',
    textDecoration: 'none',
    color: 'black',
    [theme.breakpoints.up('md')]: {
      fontSize: 'large'
    }
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    [theme.breakpoints.up('md')]: {
      width: '35px',
      height: '35px',
      margin: '0px 20px'
    },
    width: '25px',
    height: '25px',
    margin: '0px 10px'
  }
}));

const apiUrl = window.apiUrl
const SSOUrl = window.SSOUrl

export default function App() {

  const classes = useStyles()

  const [status, setStatus] = useState({})
  const [snackbar, setSnackbar] = useState(false);
  function updateStatus(severity, message) {
    setStatus({ severity, message })
    setSnackbar(true)
  }

  const [token, setToken] = useState(null)
  function tokenSetter(input) {
    setToken(input)
  }

  if (!token && !window.location.pathname.includes("Token/")) {
    window.location.href = SSOUrl
  }

  async function httpcall(type, url, payload, callback) {
    if (token) {
      var content;
      try {
        switch (type) {
          case "get": {
            content = await axios.get(apiUrl + url, { headers: { token: token } })
              .catch(error => { throw { status: error.response.status, data: error.response.data } });
            callback(content.data)
            break
          }
          case "post": {
            content = await axios.post(apiUrl + url, payload ? payload : null)
            callback()
            break
          }
          case "update": {
            content = await axios.update(apiUrl + url)
            callback()
            break
          }
          case "delete": {
            content = await axios.delete(apiUrl + url)
            callback()
            break
          }
        }
      }
      catch (exception) {
        if (exception.status && exception.status === 401) {
          window.location.href = SSOUrl
        } else if (exception.status && exception.status === 403) {
          updateStatus("warning", "This action is forbiden")
        } else {
          updateStatus("error", "request failed (" + exception.status + ")")
        }
      }
    }
  }

  return (<>
    <BrowserRouter>
      <div>
        <div className={classes.appBar}>
          <SortIcon className={classes.icon} />
          <Button className={classes.button}>
            <Link to="/" className={classes.link}>
              Home
            </Link>
          </Button>
          <Button className={classes.button}>
            <Link to="/Stats" className={classes.link}>
              Stats
            </Link>
          </Button>
        </div>
        <Routes>
          <Route path="/Token/:token" element={<TokenHandler setter={tokenSetter} />} />
          <Route path="/Stats" element={<Stats statusUpdate={updateStatus} httpcall={httpcall} />} />
          <Route path="/" element={<Home statusUpdate={updateStatus} httpcall={httpcall} />} />
        </Routes>
      </div>
    </BrowserRouter>
    <Snackbar
      open={snackbar}
      autoHideDuration={3000}
      onClose={() => { setSnackbar(false) }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert severity={status.severity ? status.severity : "error"} variant="filled">
        {status.message ? status.message : "error, no data transmited to this box"}
      </Alert>
    </Snackbar>
  </>)
}

//#region tokenhandler
const TokenHandler = (props) => {
  let { token } = useParams()
  const navigate = useNavigate()
  const [tempToken, setTempToken] = useState(null)

  useEffect(() => {
    if (tempToken == null) {
      setTempToken(token)
    } else {
      props.setter(tempToken)
      navigate("/")
    }
  }, [tempToken]);

  return (
    <></>
  )
}
//#endregion
