import { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment'

import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  Divider
} from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 15px'
    }
  },
  logRoot: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '700px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    borderRadius: '15px',
    backgroundColor: '#ddd',
    width: '100%',
    overflowWrap: 'break-word',
    margin: '5px 0px',
    padding: '15px'
  },
  floatingButton: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: "orange",
    '&:hover': {
      backgroundColor: 'orange',
      color: 'red',
    }
  },
  count: {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
  },
  filterGrid: {
    margin: "10px 0px"
  },
  validateButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }
}));

function Home(props) {
  const classes = useStyles()
  const theme = useTheme()

  const [loading, setLoading] = useState(false)
  const [dialog, setDialog] = useState(false)
  const [doOnce, setDoOnce] = useState(true)

  useEffect(() => {
    if (doOnce) {
      setDoOnce(false)
      props.httpcall("get", "getcontext", null, callbackContext)
      props.httpcall("get", "get" + getAPIParameters(), null, callbackLogs)
    }
  })

  const [applications, setApplications] = useState(null)
  const [logs, setLogs] = useState(null)

  const [currentApplication, setCurrentApplication] = useState({ name: "None", methods: [], id: "0" })
  const [currentMethod, setCurrentMethod] = useState("None")
  const [currentType, setCurrentType] = useState("All")
  const [currentTimeType, setCurrentTimeType] = useState("Period")
  const [currentPeriod, setCurrentPeriod] = useState("1 week")
  const [currentStartDate, setCurrentStartDate] = useState("")
  const [currentEndDate, setCurrentEndDate] = useState("")
  const [currentDate, setCurrentDate] = useState("")

  function getAPIParameters() {
    var parameters = []
    if (currentPeriod !== "None") {
      parameters.push('&period=' + currentPeriod)
    }
    if (currentDate !== "") {
      parameters.push('&specificDate=' + currentDate)
    }
    if (currentStartDate !== "") {
      parameters.push('&startDate=' + currentStartDate)
    }
    if (currentEndDate !== "") {
      parameters.push('&endDate=' + currentEndDate)
    }
    if (currentType !== "All") {
      parameters.push('&type=' + currentType)
    }
    if (currentApplication.name !== "None") {
      parameters.push('&application=' + currentApplication.name)
    }
    if (currentMethod !== "None") {
      parameters.push('&method=' + currentMethod)
    }

    if (parameters.length > 0) {
      parameters[0] = parameters[0].replace('&', '?')
    }

    return parameters.join()
  }

  function callbackContext(data) {
    setApplications(data)
    setLoading(false)
  }

  function callbackLogs(data) {
    setLogs(data)
    setLoading(false)
    setDialog(false)
    props.statusUpdate("info", data.length + " logs loaded")
  }

  const handleApplication = (event) => {
    if (event.target.value === "None") {
      setCurrentApplication({ name: "None", methods: [], id: "0" })
    } else {
      for (let i in applications) {
        if (applications[i].name === event.target.value) {
          setCurrentApplication(applications[i])
        }
      }
    }
  }

  const handleMethod = (event) => {
    if (event.target.value === "None") {
      setCurrentMethod("")
    } else {
      setCurrentMethod(event.target.value)
    }
  }

  const handleTimeType = (event) => {
    setCurrentTimeType(event.target.value)
    setCurrentPeriod("None")
    setCurrentStartDate("")
    setCurrentEndDate("")
    setCurrentDate("")
  }

  return (
    <>
      <div className={classes.content}>
        {logs?.map((log) => {
          return (
            <div key={log.id} className={classes.logRoot}>
              <Grid container spacing={0} justifyContent="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Typography>
                    {log.type}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" align="right">
                    {moment(log.date).format("DD/MM/YYYY HH:mm:ss")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    {log.application}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {log.method}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {log.message}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="right">
                    {log.identity}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )
        })}
        {loading ??
          <Box display="flex" alignItems="center" justifyContent="center" margin="10px">
            <CircularProgress />
          </Box>
        }
      </div>
      {useMediaQuery(theme.breakpoints.up('sm')) ?
        <Typography variant="h6" className={classes.count}>
          {logs != null ? logs.length : 0} log(s)
        </Typography>
        :
        <IconButton className={classes.count} disabled>
          {logs != null ? logs.length : 0}
        </IconButton >}
      <IconButton className={classes.floatingButton} onClick={() => setDialog(true)}>
        <SortIcon fontSize="large" />
      </IconButton >
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent style={{ minWidth: "300px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h5">
              Filter
            </Typography>
            <Grid item className={classes.filterGrid}>
              <TextField variant="outlined" select label="Application" value={currentApplication.name === "None" ? "" : currentApplication.name} fullWidth onChange={handleApplication}>
                <MenuItem value="None">
                  None
                </MenuItem>
                {applications !== null ?
                  applications.map((application) => {
                    return (
                      <MenuItem key={application.name} value={application.name}>
                        {application.name}
                      </MenuItem>
                    )
                  })
                  : <MenuItem value={""}>
                    None
                  </MenuItem>}
              </TextField>
            </Grid>
            {currentApplication.name !== "None" ?
              <Grid item className={classes.filterGrid}>
                <TextField select label="Method" value={currentMethod === "None" ? "" : currentMethod} variant="outlined" fullWidth onChange={handleMethod}>
                  <MenuItem value="None">
                    None
                  </MenuItem>
                  {currentApplication.methods.map((method) => {
                    return (
                      <MenuItem key={method} value={method}>
                        {method}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              : <></>}
            <Grid item className={classes.filterGrid}>
              <TextField select label="Log type" value={currentType} variant="outlined" fullWidth onChange={(event) => setCurrentType(event.target.value)}>
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Info">Info</MenuItem>
                <MenuItem value="Warning">Warning</MenuItem>
                <MenuItem value="Error">Error</MenuItem>
              </TextField>
            </Grid>
            <Grid item className={classes.filterGrid}>
              <TextField select label="Time mode" value={currentTimeType === "None" ? "" : currentTimeType} variant="outlined" fullWidth onChange={handleTimeType}>
                <MenuItem value="None">None</MenuItem>
                <MenuItem value="Period">Period</MenuItem>
                <MenuItem value="Start-End">Start-End</MenuItem>
                <MenuItem value="Specific Date">Specific Date</MenuItem>
              </TextField>
            </Grid>
            {currentTimeType === "Period" ?
              <Grid item className={classes.filterGrid}>
                <TextField select label="Period" value={currentPeriod} variant="outlined" fullWidth onChange={(event) => setCurrentPeriod(event.target.value)}>
                  <MenuItem value="today">
                    today
                  </MenuItem>
                  <MenuItem value="1 week">
                    1 week
                  </MenuItem>
                  <MenuItem value="1 month">
                    1 month
                  </MenuItem>
                  <MenuItem value="3 month">
                    3 month
                  </MenuItem>
                  <MenuItem value="6 month">
                    6 month
                  </MenuItem>
                  <MenuItem value="all">
                    all
                  </MenuItem>
                </TextField>
              </Grid>
              : <></>}
            {currentTimeType === "Start-End" ?
              <Grid item>
                <div className={classes.filterGrid}>
                  <TextField label="Start Date (dd-mm-yyyy)" value={currentStartDate} variant="outlined" fullWidth onChange={(event) => setCurrentStartDate(event.target.value)} />
                </div>
                <div item className={classes.filterGrid}>
                  <TextField label="End Date (dd-mm-yyyy)" value={currentEndDate} variant="outlined" fullWidth onChange={(event) => setCurrentEndDate(event.target.value)} />
                </div>
              </Grid>
              : <></>}
            {currentTimeType === "Specific Date" ?
              <Grid item className={classes.filterGrid}>
                <TextField label="Date (dd-mm-yyyy)" value={currentDate} variant="outlined" fullWidth onChange={(event) => setCurrentDate(event.target.value)} />
              </Grid>
              : <></>}
            <Grid item className={classes.validateButton}>
              {loading ?
                <Box display="flex" alignItems="center" justifyContent="center" margin="10px">
                  <CircularProgress />
                </Box>
                : <IconButton style={{ color: "green" }} onClick={() => { setLoading(true); props.httpcall("get", "get" + getAPIParameters(), null, callbackLogs) }}>
                  <CheckCircleIcon fontSize="large" />
                </IconButton>}
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Home
